html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  grid-template-rows: auto 1fr;
}

/* this is trying to hide CRA' error overlay from showing up on dev-mode */
body > iframe {
  display: none;
}

html {
  overscroll-behavior-x: none;
}

body.dragging * {
  cursor: grabbing !important;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 2px;
}

@keyframes ping {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.pinging {
  animation: ping 1s linear infinite;
}

.monaco-editor .suggest-widget {
  width: 100% !important;
}

.app-builder-jspi1t-TreeContainer .svgWhiteStrokeOnSidebar {
  stroke:white;
}

.app-builder-jspi1t-TreeContainer .svgNoFillOnSidebar {
  fill:none !important;
}

.app-builder-jspi1t-TreeContainer .svgWhiteFillOnSidebar {
  fill:white !important;
}

.app-builder-jspi1t-TreeContainer .svgStrokeEqualToBackgroundOnSidebar {
  stroke:#323C47;
}

.app-builder-jspi1t-TreeContainer .svgFillEqualToBackgroundOnSidebar {
  fill:#323C47;
}

.app-builder-jspi1t-TreeContainer .svgNoStrokeOnSidebar {
  stroke-width:0;
}


